<template>
   <div>
     <v-dialog
         scrollable
         v-model="modalPrint"
         width="820">
       <v-card>
         <v-card-title class="text-h5 font-weight-bold">
           <span class="title">Run example print</span>
         </v-card-title>
         <v-card-text>
           <prism-editor class="my-editor" v-model="printTemplate" :highlight="highlighter" line-numbers></prism-editor>
         </v-card-text>
         <v-card-actions>
           <v-spacer></v-spacer>
           <v-spacer></v-spacer>
           <v-row class="pt-4 pb-4 mr-3 justify-end">
             <v-btn color="blue darken-1" text @click="modalPrint = false">Close</v-btn>
             <v-btn color="blue darken-1" text @click="runPrint">Run</v-btn>
           </v-row>
         </v-card-actions>
       </v-card>
     </v-dialog>
     <div style="width: 100%;bottom: 16px; position: fixed; display: flex; margin-left: 30%; z-index: 999;"
          v-if="alertMsg">
       <v-alert type="info">
         {{ alertMsg }}
       </v-alert>
     </div>
     <v-navigation-drawer
         v-model="drawer"
         width="300"
         app
         permanent
     ><div class="justify-space-between flex-column d-flex" style="height: 100%">
         <div>
           <v-list-item class="px-2">
             <v-list-item-avatar>
               <v-img src="../assets/logo.png"></v-img>
             </v-list-item-avatar>
             <v-list-item-title>Systems SDK</v-list-item-title>
           </v-list-item>
           <v-divider></v-divider>
 
           <v-list-item-group dense v-model="tab">
             <v-list-item
                 v-for="item in items"
                 :key="item.title"
                 link
                 :disabled="item.disabled">
               <v-list-item-icon>
                 <v-icon>{{ item.icon }}</v-icon>
               </v-list-item-icon>
               <v-list-item-content>
                 <v-list-item-title>{{ item.title }}</v-list-item-title>
               </v-list-item-content>
             </v-list-item>
           </v-list-item-group>
         </div>
         <div class="pa-4 d-flex align-start justify-center flex-column">
           <span><b>App Name:</b></span>
           <span>{{ appName }}</span>
           <span v-if="deviceState.device || !deviceState.device"><b>Device name</b>:</span>
           <span>{{ deviceState.device ? deviceState.device.name:"N/A"}}            </span>
           <span v-if="deviceState.device || !deviceState.device"><b>UUID</b>:</span>
           <span class="caption">{{deviceState.device?deviceState.device.uuid:"N/A"}}</span>
           <span v-if="deviceState.location || !deviceState.location"><b>Location name</b>:</span>
           <span>{{deviceState.location?deviceState.location.name:"N/A"}}</span>
           <span v-if="deviceState.space || !deviceState.space"><b>Space name</b>: </span>
           <span>{{deviceState.space?deviceState.space.name:"N/A"}}</span>
           <div class="d-flex align-center">
             <div :class="['circle', {'green': lpsConnection, 'red': !lpsConnection}]"></div>
             <span class="ml-2">LPS {{ lpsConnection ? 'connected' : 'disconnected' }}</span>
           </div>
         </div>
       </div>
     </v-navigation-drawer>
     <!--- Money --->
     <div v-if="tab === 0" class="content-wrapper pl-3">
       <p class="ma-2 subtitle-1 font-weight-medium">Money</p>
       <v-row class="ma-2 mt-4">
         <v-col cols="9" class="content-border mr-4 pa-6">
           <p class="subtitle-2">Money-related messages</p>
           <v-row dense class="event pa-2 " v-for="(event, i) in sdkMoney.events" v-bind:key="i">
             <v-col cols="2">
               <span>{{ event.event }}</span>
             </v-col>
             <v-col offset="1" cols="7" style="white-space: nowrap; overflow: hidden">
               <p style="text-overflow: clip; cursor: pointer" @click="copyResponse(event.response)">{{
                   event.response
                 }}</p>
             </v-col>
             <v-col cols="2" class="text-right">
               <p>{{ event.time }}</p>
             </v-col>
           </v-row>
         </v-col>
         <v-col  class="content-border pa-6 mr-2">
           <p class="subtitle-2">Actions</p>
           <v-btn depressed color="gray" class="letter-spacing-normal text-capitalize mt-2" block
                  @click="getStatus('bill')">
             <v-icon color="blue">mdi-information-outline</v-icon>
             <span class="ml-2">Get bill status</span>
           </v-btn>
           <v-btn depressed color="gray" class="letter-spacing-normal text-capitalize mt-2" block
                  @click="getStatus('coin')">
             <v-icon color="blue">mdi-information-outline</v-icon>
             <span class="ml-2">Get coin status</span>
           </v-btn>
           <v-btn depressed color="gray" class="letter-spacing-normal text-capitalize mt-2" block
                  @click="getPeripherals">
             <v-icon color="blue">mdi-devices</v-icon>
             <span class="ml-2">Get peripherals</span>
           </v-btn>
           <v-btn depressed color="gray" class="letter-spacing-normal text-capitalize mt-8" block
                  @click="changeAcceptorStatus('coin', 'start')">
             <v-icon color="green">mdi-hand-coin-outline</v-icon>
             <span class="ml-2">Start coin acceptor</span>
           </v-btn>
           <v-btn depressed color="gray" class="letter-spacing-normal text-capitalize mt-2" block
                  @click="changeAcceptorStatus('coin', 'stop')">
             <v-icon color="red">mdi-hand-coin-outline</v-icon>
             <span class="ml-2">Stop coin acceptor</span>
           </v-btn>
           <v-btn depressed color="gray" class="letter-spacing-normal text-capitalize mt-8" block
                  @click="changeAcceptorStatus('bill', 'start')">
             <v-icon color="green">mdi-cash</v-icon>
             <span class="ml-2">Start Bill acceptor</span>
           </v-btn>
           <v-btn depressed color="gray" class="letter-spacing-normal text-capitalize mt-2" block
                  @click="changeAcceptorStatus('bill', 'stop')">
             <v-icon color="red">mdi-cash</v-icon>
             <span class="ml-2">Stop Bill acceptor</span>
           </v-btn>
           <v-btn depressed color="gray" class="letter-spacing-normal text-capitalize mt-8" block
                  @click="acceptMoney(true)">
             <v-icon color="green">mdi-currency-usd</v-icon>
             <span class="ml-2">Start all acceptors</span>
           </v-btn>
           <v-btn depressed color="gray" class="letter-spacing-normal text-capitalize mt-2" block
                  @click="acceptMoney(false)">
             <v-icon color="red">mdi-currency-usd-off</v-icon>
             <span class="ml-2">Stop all acceptors</span>
           </v-btn>
         </v-col>
       </v-row>
     </div>
     <!-- Card Reader -->
     <div v-if="tab === 1" class="content-wrapper mx-2">
   <p class="ma-2 subtitle-1 font-weight-medium">Card reader</p>
   <v-row class="ma-2 mt-4">
     <v-col cols="8" class="content-border">
       <p class="subtitle-2">Card reader related messages</p>
       <v-row dense class="event pa-2 " v-for="(event, i) in sdkCard.events" v-bind:key="i">
         <v-col cols="2">
           <span>{{ event.event }}</span>
         </v-col>
         <v-col offset="1" cols="7" style="white-space: nowrap; overflow: hidden">
           <p style="text-overflow: clip; cursor: pointer" @click="copyResponse(event.response)">{{
             event.response
           }}</p>
         </v-col>
         <v-col cols="2" class="text-right">
           <p>{{ event.time }}</p>
         </v-col>
       </v-row>
     </v-col>
     <v-col class="content-border mr-2 ml-4 pa-6">
       <p class="subtitle-2">Actions</p>
       <v-row class="ma-0 mt-3 mb-3 pa-4" style="border: 1px solid #F5F5F5; border-radius: 6px;">
         <v-text-field outlined dense class="mt-3" v-model="cardText" :counter="128" :maxlength="128"></v-text-field>
         <v-btn depressed color="gray" class="letter-spacing-normal text-capitalize pa-4" block @click="cardWrite()">
           <v-icon color="blue">mdi-pencil</v-icon>
           <span class="ml-2">Write on card</span>
         </v-btn>
         <p class="subtitle-2 mt-4">Actions card reader</p>
         <v-btn depressed 
         color="gray" 
         class="letter-spacing-normal text-capitalize pa-4 mb-2" 
         block 
         @click="startCard()">
         <v-icon color="green">mdi-smart-card-reader</v-icon>
         <span class="ml-2">Enable card reader</span>
      </v-btn>
         <v-btn 
         depressed 
         color="gray" 
         class="letter-spacing-normal text-capitalize pa-4" 
         block 
         @click="stopCard()">
         <v-icon color="red">mdi-smart-card-reader</v-icon>
           <span class="ml-2">Disable card reader</span>
      </v-btn>
         <v-btn 
         depressed 
         color="gray" 
         class="letter-spacing-normal text-capitalize pa-4 mt-2" 
         block 
         @click="cardReadMock()">
         <v-icon color="blue">mdi-smart-card-reader</v-icon>
           <span class="ml-2">Mock card read</span>
      </v-btn>
       </v-row>
     </v-col>
   </v-row>
 </div>
 <template>
   <v-dialog v-model="cardWriteModal" width="500" height="500" >
     <v-card>
       <v-card-title class="headline">Card Confirmation</v-card-title>
       <v-card-text>To write <strong><span v-text="cardText"></span></strong>  to card, please hold card on card holder.</v-card-text>
       <v-card-actions>
         <v-btn color="red" text @click="cardWriteModal = false">Cancel</v-btn>
       </v-card-actions>
     </v-card>
   </v-dialog>
 </template>
     <!--- Print --->
     <div v-if="tab === 2" class="content-wrapper ml-2">
       <p class="ma-2 subtitle-1 font-weight-medium">Print</p>
       <v-row class="ma-2 mt-4">
         <v-col cols="8" class="content-border mr-4 pa-6">
           <p class="subtitle-2">Print-related messages</p>
           <v-row dense class="event pa-2" v-for="(event, i) in sdkPrint.events" v-bind:key="i">
             <v-col cols="2" class="tooltip">
               <span >{{ event.event }}</span>
             </v-col>
             <v-col offset="1" cols="7" style="white-space: nowrap; overflow: hidden">
               <p style="text-overflow: clip; cursor: pointer" @click="copyResponse(event.response)">{{
                   event.response
                 }}</p>
             </v-col>
             <v-col cols="2" class="text-right">
               <p>{{ event.time }}</p>
             </v-col>
           </v-row>
         </v-col>
         <v-col class="content-border mr-2 pa-6">
           <p class="subtitle-2">Actions</p>
           <v-btn depressed color="gray" class="letter-spacing-normal text-capitalize mt-2" block
                  @click="getPrintServiceInfo">
             <v-icon color="blue">mdi-information-outline</v-icon>
             <span class="ml-2">Print service info</span>
           </v-btn>
           <v-btn depressed color="gray" class="letter-spacing-normal text-capitalize mt-2" block
                  @click="getPrinters()">
             <v-icon color="blue">mdi-information-outline</v-icon>
             <span class="ml-2">Get printers</span>
           </v-btn>
           <v-btn depressed color="gray" class="letter-spacing-normal text-capitalize mt-2" block
                  @click="getPrintersIndex">
             <v-icon color="blue">mdi-information-outline</v-icon>
             <span class="ml-2">Get printers index</span>
           </v-btn>
           <v-btn depressed color="gray" class="letter-spacing-normal text-capitalize mt-8" block
                  @click="getPrinterStatus">
             <v-icon color="green">mdi-information-outline</v-icon>
             <span class="ml-2">Get printer status</span>
           </v-btn>
           <v-btn depressed color="gray" class="letter-spacing-normal text-capitalize mt-2 mb-2" block
                  @click="printModal()">
             <v-icon color="green">mdi-printer</v-icon>
             <span class="ml-2">Run example print</span>
           </v-btn>
           <div class="pa-4 mt-10" style="border: 1px solid #EEEEEE; border-radius: 8px;">
             <v-select
               v-model="faultName"
               :items="itemsPrint"
               outlined
               dense
               filled
               label="Printers faults"
             ></v-select>
             <v-row>
               <v-col cols="4">
                 <!-- Use method without 'this' for template expressions in Vue -->
                 <v-btn @click="setPrinterStatusFault(faultName, 'ok')" small color="primary" elevation="0">Clear Error</v-btn>
               </v-col>
               <v-col cols="4">
                 <v-btn @click="setPrinterStatusFault(faultName, 'error')" small color="error" elevation="0">Set Error</v-btn>
               </v-col>
             </v-row>
           </div>
 
         </v-col>
       </v-row>
     </div>
     <!--Fetcher service-->
     <div v-if="tab === 3" class="content-wrapper mx-2">
       <p class="ma-2 subtitle-1 font-weight-medium">Fetch content</p>
       <v-row class="ma-2 mt-4">
         <v-col cols="7" class="content-border">
           <p class="subtitle-2">Fetcher related messages</p>
           <v-row dense class="event pa-2 " v-for="(event, i) in sdkFetcher.events" v-bind:key="i">
             <v-col cols="2">
               <span>{{ event.event }}</span>
             </v-col>
             <v-col offset="1" cols="7" style="white-space: nowrap; overflow: hidden">
               <p style="text-overflow: clip; cursor: pointer" @click="copyResponse(event.response)">{{
                   event.response
                 }}</p>
             </v-col>
             <v-col cols="2" class="text-right">
               <p>{{ event.time }}</p>
             </v-col>
           </v-row>
 
         </v-col>
         <v-col class="content-border mr-2 ml-4 pa-6">
           <p class="subtitle-2">Actions</p>
           <v-row class="ma-0 mt-3 mb-3 pa-4" style="border: 1px solid #F5F5F5; border-radius: 6px;">
               <v-text-field outlined dense class="mt-3" v-model="dataValue"></v-text-field>
               <v-btn depressed color="gray" class="letter-spacing-normal text-capitalize pa-4" block
                  @click="getContent()">
             <v-icon color="blue">mdi-information-outline</v-icon>
             <span class="ml-2">Download Content</span>
           </v-btn>
           </v-row>
           <v-btn depressed color="gray" class="letter-spacing-normal text-capitalize mt-2" block>
             <v-icon color="blue">mdi-information-outline</v-icon>
             <span class="ml-2">Fetcher service info</span>
           </v-btn>
           <v-divider class="my-3"></v-divider>
           <div>
         <v-card class="mb-2" v-for="(eventGroup, index) in eventGroups" :key="index">
           <v-card-subtitle class="my-0 py-1">Checking: {{ eventGroup.checkProgress }}<v-progress-linear v-if="eventGroup.checkProgress !== undefined" :value="eventGroup.checkProgress"></v-progress-linear></v-card-subtitle>
           <v-card-subtitle class="my-0 py-1">Downloading: {{ eventGroup.filesToDownload  + " / " + eventGroup.downloadProgress }} <v-progress-linear v-if="eventGroup.downloadProgress !== undefined" :value="eventGroup.downloadProgress"></v-progress-linear><span v-else-if="eventGroup.downloadProgress === 0">Nothing to download</span></v-card-subtitle>
           <v-card-subtitle class="my-0 py-0">Files Changed: {{ eventGroup.location }}</v-card-subtitle>
           <v-card-actions class="my-0 py-2"><v-btn @click="stopDownload(eventGroup.request_uuid)" small >Stop Downloading</v-btn></v-card-actions>
         </v-card>
         <!-- Conditionally show a message or content when there is at least one object in eventGroups -->
         <div v-if="eventGroups.length === 0">
           <p>No events to display.</p>
         </div>
       </div>
         </v-col>
       </v-row>
     </div>
       <!--Relay-->
       <div v-if="tab === 4" class="content-wrapper ml-2">
       <p class="ma-2 subtitle-1 font-weight-medium">Relay</p>
       <v-row class="ma-2 mt-4">
         <v-col cols="8" class="content-border mr-4 pa-6">
           <p class="subtitle-2">Relay-related messages</p>
           <v-row dense class="event pa-2" v-for="(event, i) in sdkRelay.events" v-bind:key="i">
             <v-col cols="2" class="tooltip">
               <span >{{ event.event }}</span>
             </v-col>
             <v-col offset="1" cols="7" style="white-space: nowrap; overflow: hidden">
               <p style="text-overflow: clip; cursor: pointer" @click="copyResponse(event.response)">{{
                   event.response
                 }}</p>
             </v-col>
             <v-col cols="2" class="text-right">
               <p>{{ event.time }}</p>
             </v-col>
           </v-row>
         </v-col>
         <v-col class="content-border mr-2 pa-6" >
           <p class="subtitle-2">Actions</p>
           <v-btn depressed color="gray" class="letter-spacing-normal text-capitalize mt-2" block
                  @click="getDeviceState">
             <v-icon color="blue">mdi-information-outline</v-icon>
             <span class="ml-2">Get Device State</span>
           </v-btn>
           <v-btn depressed color="gray" class="letter-spacing-normal text-capitalize mt-2" block
                  @click="getDisplayApplications">
             <v-icon color="blue">mdi-information-outline</v-icon>
             <span class="ml-2">Get Display Applications</span>
           </v-btn>
           <v-btn depressed color="gray" class="letter-spacing-normal text-capitalize mt-8" block
                  @click="getPrinterStatus">
             <v-icon color="green">mdi-information-outline</v-icon>
             <span class="ml-2">Relay Service Info</span>
           </v-btn>
          
         </v-col>
       </v-row>
     </div>
     <!--Display-->
     <div v-if="tab === 5" class="content-wrapper mx-2">
       <p class="ma-2 subtitle-1 font-weight-medium">Display</p>
       <v-row class="ma-2 mt-4">
         <v-col cols="8" class="content-border">
           <p class="subtitle-2">Display related messages</p>
           <v-row dense class="event pa-2 " v-for="(event, i) in sdkDisplay.events" v-bind:key="i">
             <v-col cols="2">
               <span>{{ event.event }}</span>
             </v-col>
             <v-col offset="1" cols="7" style="white-space: nowrap; overflow: hidden">
               <p style="text-overflow: clip; cursor: pointer" @click="copyResponse(event.response)">{{
                   event.response
                 }}</p>
             </v-col>
             <v-col cols="2" class="text-right">
               <p>{{ event.time }}</p>
             </v-col>
           </v-row>
         </v-col>
         <v-col class="content-border mr-2 ml-4 pa-6">
           <p class="subtitle-2">Actions</p>
           <v-row class="ma-0 mt-3 mb-3 pa-4" style="border: 1px solid #F5F5F5; border-radius: 6px;">
               <v-text-field outlined dense placeholder="Uuid" class="mt-3" v-model="appRunUuid"></v-text-field>
               <v-btn depressed color="gray" class="letter-spacing-normal text-capitalize pa-4" block
                  @click="getContentDisplay()">
             <v-icon color="blue">mdi-information-outline</v-icon>
             <span class="ml-2">Get Application Config</span>
           </v-btn>
           </v-row>
           <v-row class="ma-0 mt-3 mb-3 pa-4" style="border: 1px solid #F5F5F5; border-radius: 6px;">
               <v-text-field outlined dense placeholder="cpvuuid" class="mt-3" v-model="appId"></v-text-field>
               <v-btn depressed color="gray" class="letter-spacing-normal text-capitalize pa-4" block
                  @click="applicationToFront()">
             <v-icon color="blue">mdi-information-outline</v-icon>
             <span class="ml-2">Bring application to front</span>
           </v-btn>
           </v-row>
           <v-btn depressed color="gray" class="letter-spacing-normal text-capitalize mt-2" block
           @click="getListRunningApps()">
             <v-icon color="blue">mdi-information-outline</v-icon>
             <span class="ml-2">Load Display Apps</span>
           </v-btn>
           <v-btn depressed color="gray" class="letter-spacing-normal text-capitalize mt-2" block
           @click="appHeartbeat()">
             <v-icon color="blue">mdi-heart</v-icon>
             <span class="ml-2">App heartbeat send</span>
           </v-btn>
         </v-col>
       </v-row>
     </div>
     <!--Pocket-->
     <div v-if="tab === 6" class="content-wrapper mx-2">
       <p class="ma-2 subtitle-1 font-weight-medium">Pocket</p>
       <v-row class="ma-2 mt-4">
         <v-col cols="8" class="content-border">
           <p class="subtitle-2">Pocket related messages</p>
           <v-row dense class="event pa-2 " v-for="(event, i) in sdkPocket.events" v-bind:key="i">
             <v-col cols="2">
               <span>{{ event.event }}</span>
             </v-col>
             <v-col offset="1" cols="7" style="white-space: nowrap; overflow: hidden">
               <p style="text-overflow: clip; cursor: pointer" @click="copyResponse(event.response)">{{
                   event.response
                 }}</p>
             </v-col>
             <v-col cols="2" class="text-right">
               <p>{{ event.time }}</p>
             </v-col>
           </v-row>
         </v-col>
         <v-col class="content-border mr-2 ml-4 pa-6">
           <p class="subtitle-2">Actions</p>
           <v-row class="ma-0 mt-3 mb-3 pa-4" style="border: 1px solid #F5F5F5; border-radius: 6px;">
               <v-text-field outlined dense placeholder="id" class="mt-3" v-model="appRunUuid"></v-text-field>
               <v-btn depressed color="gray" class="letter-spacing-normal text-capitalize pa-4" block
                  @click="cancelTransactions()">
             <v-icon color="red">mdi-information-outline</v-icon>
             <span class="ml-2">Cancel transaction</span>
           </v-btn>
           </v-row>
           <v-btn depressed color="gray" class="letter-spacing-normal text-capitalize mt-2" block
           @click="fetchTransactions()">
             <v-icon color="blue">mdi-information-outline</v-icon>
             <span class="ml-2">Fetch transactions</span>
           </v-btn>
           <v-btn depressed color="gray" class="letter-spacing-normal text-capitalize mt-2" block
           @click="fetchTransactionApoens()">
             <v-icon color="blue">mdi-information-outline</v-icon>
             <span class="ml-2">Fetch transaction apoens</span>
           </v-btn>
           <v-btn depressed color="gray" class="letter-spacing-normal text-capitalize mt-2" block
           @click="emptyCashBox()">
             <v-icon color="blue">mdi-information-outline</v-icon>
             <span class="ml-2">Empty cashbox</span>
           </v-btn>
           <v-col>
            <v-row class="ma-0" style="border: 1px solid #F5F5F5; border-radius: 6px;">
               <v-row>
               <v-col>
                  <v-card elevation="0" class="pa-4">
                     <v-card-subtitle>Virtual money insert</v-card-subtitle>
                     <v-text-field class="px-2" outlined dense placeholder="Enter device type coin or bill" v-model="deviceMock"></v-text-field>
                     <v-text-field class="px-2" outlined dense placeholder="Enter money amount" v-model="amountMock"></v-text-field>
                     <v-btn small class="ml-2" color="primary" @click="sendTransactionMock()">Submit</v-btn>
                  </v-card>
               </v-col>
            </v-row>
            </v-row>
         </v-col>
         </v-col>
       </v-row>
     </div>
     <div v-if="tab === 7">
      <p class="ma-2 subtitle-1 font-weight-medium">Regula</p>
       <v-row class="ma-2 mt-4">
         <v-col cols="8" class="content-border">
           <p class="subtitle-2">Regula related messages</p>
           <v-row dense class="event pa-2 " v-for="(event, i) in sdkRegula.events" v-bind:key="i">
             <v-col cols="2">
               <span>{{ event.event }}</span>
             </v-col>
             <v-col offset="1" cols="7" style="white-space: nowrap; overflow: hidden">
               <p style="text-overflow: clip; cursor: pointer" @click="copyResponse(event.response)">{{
                   event.response
                 }}</p>
             </v-col>
             <v-col cols="2" class="text-right">
               <p>{{ event.time }}</p>
             </v-col>
           </v-row>
         </v-col>
         <v-col class="content-border mr-2 ml-4 pa-6">
           <p class="subtitle-2">Actions</p>
           <v-btn depressed color="gray" class="letter-spacing-normal text-capitalize mt-2" block
           @click="checkRegulaService()">
             <v-icon color="blue">mdi-information-outline</v-icon>
             <span class="ml-2">Check Regula</span>
           </v-btn>
           <v-btn depressed color="success" class="letter-spacing-normal text-capitalize mt-2" block
           @click="startRegulaService()">
             <v-icon>mdi-information-outline</v-icon>
             <span class="ml-2">Start Regula</span>
           </v-btn>
           <v-btn depressed color="error" class="letter-spacing-normal text-capitalize mt-2" block
           @click="stopRegulaService()">
             <v-icon>mdi-information-outline</v-icon>
             <span class="ml-2">Stop regula</span>
           </v-btn>
           <v-col>
         </v-col>
         </v-col>
       </v-row>
     </div>
     <template>
   <div class="text-center">
     <v-dialog
       scrollable
       v-model="dialog"
       width="700">
       <v-card>
         <v-card-title class="text-h5 grey lighten-2">
         Peripherals data
         </v-card-title>
         <v-card-text v-if="this.sdkPrint.events.length > 0">
           <pre>{{ formatDataForDialog }}</pre>
         </v-card-text>
         <v-card-text v-else-if="this.sdkMoney.events.length > 0">
           <pre>{{ formatDataForDialog }}</pre>
         </v-card-text>
         <v-card-text v-else-if="this.sdkCard.events.length > 0">
           <pre>{{ formatDataForDialog }}</pre>
         </v-card-text>
         <v-card-text v-else-if="this.sdkFetcher.events.length > 0">
           <pre>{{ formatDataForDialog }}</pre>
         </v-card-text>
         <v-card-text v-else-if="this.sdkRelay.events.length > 0">
           <pre>{{ formatDataForDialog }}</pre>
         </v-card-text>
         <v-card-text v-else-if="this.sdkDisplay.events.length > 0">
           <pre>{{ formatDataForDialog }}</pre>
         </v-card-text>
         <v-card-text v-else-if="this.sdkPocket.events.length > 0">
           <pre>{{ formatDataForDialog }}</pre>
         </v-card-text>
         <v-card-text  v-else-if="this.sdkRegula.events.length > 0">
           <div>
            <pre>{{ formatDataForDialog }}</pre>
           </div>
         </v-card-text>
         <v-divider></v-divider>
         <v-card-actions>
           <v-spacer></v-spacer>
           <v-btn
             color="primary"
             text
             @click="dialog = false">
             Close
           </v-btn>
         </v-card-actions>
       </v-card>
     </v-dialog>
   </div>
 </template>
 </div>
 </template>
 
 <script>
 import {Core, Card, Money, Print, Fetcher, Display, Pocket, Regula} from "@nsftx/systems-sdk";
 import {PrismEditor} from 'vue-prism-editor';
 import 'vue-prism-editor/dist/prismeditor.min.css';
 import {highlight, languages} from 'prismjs/components/prism-core';
 import 'prismjs/components/prism-markup';
 
 export default {
   components: {
     PrismEditor
   },
   mounted() {
     this.sys = new Core("demo", "http://10.10.4.110:5000/centralHub");
     this.appName = 'sdk-' + this.sys.appName;
     try {
       this.sys.connect(); 
     } catch (error) {
       console.log(error)
     }
     this.sys.on('connection', () => {
       this.lpsConnection = true;
       this.sys.deviceState().then(res => {
         this.deviceState = res;
         this.dataMessage = true;
         console.log(res)
       });
       this.money = new Money(this.sys);
       console.log(this.money)
       this.money.on('ready', () => {
         this.money.on('acceptorStatus', (data) => {
           this.sdkMoney.events.unshift({
             event: 'Acceptor status',
             response: data,
             time: new Date().toLocaleTimeString()
           });
         });
       });
       this.pocket = new Pocket(this.sys);
       console.log(this.pocket)
       this.pocket.on('ready', () => {
         this.pocket.on('moneyProcessed', (data) => {
           this.sdkPocket.events.unshift({
             event: 'Money processed',
             response: JSON.stringify(data),
             time: new Date().toLocaleTimeString()
           });
         });
         this.pocket.on('moneyUnprocessed', (data) => {
           this.sdkPocket.events.unshift({
             event: 'Money unprocessed',
             response: JSON.stringify(data),
             time: new Date().toLocaleTimeString()
           });
         });
         this.pocket.on('moneyProcessing', (data) => {
           this.sdkPocket.events.unshift({
             event: 'Money processing',
             response: JSON.stringify(data),
             time: new Date().toLocaleTimeString()
           });
         });
       })
       this.card = new Card(this.sys);
       console.log(this.card)
       this.card.on('ready', (data) => {
         console.log(data + "ready123");
         this.sdkCard.connection = true;
       });
       this.card.on('cardRead', (data) => {
           this.sdkCard.events.unshift({
             event: 'Card read',
             response: data,
             time: new Date().toLocaleTimeString()
           });
         });
       this.card.on('cardOut', (data) => {
           this.sdkCard.events.unshift({
             event: 'Card Out',
             response: data,
             time: new Date().toLocaleTimeString()
           });
         });
         this.card.on('cardWrite', (data) => {
            this.cardWriteModal = false;
            this.cardText="";
           this.sdkCard.events.unshift({
             event: 'Card write',
             response: JSON.parse(data),
             time: new Date().toLocaleTimeString()
           });
         });
       this.print = new Print(this.sys, false);
       console.log(this.print);
       this.print.on('ready', () => {
         this.sdkPrint.connection = true;
       });
       this.print.on('statusChange', response => {
         this.sdkPrint.events.unshift({
             event: 'Printer Status Change',
             response: response,
             time: new Date().toLocaleTimeString()
           });
       });
       this.fetcher = new Fetcher(this.sys);
       console.log(this.fetcher)
       this.fetcher.on('ready', () => {
         this.sdkFetcher.connection = true;
       });
       this.fetcher.on('contentStatus', response => {
         this.handleIncomingEvent(response);
       });
       this.display = new Display(this.sys);
       this.display.on('ready', () => {
         this.sdkDisplay.connection = true;
       });
       this.regula = new Regula(this.sys);
       this.regula.on('ready', () => {
         this.sdkRegula.connection = true;
       });
       this.regula.on('documentScanned', response => {
         this.sdkRegula.events.unshift({
             event: 'Scanned Document',
             response: JSON.stringify(response),
             time: new Date().toLocaleTimeString()
           });
       });
       this.regula.on('enable', response => {
         this.sdkRegula.events.unshift({
             event: 'Regula enabled',
             response: response,
             time: new Date().toLocaleTimeString()
           });
       });
       this.regula.on('disable', response => {
         this.sdkRegula.events.unshift({
             event: 'Regula disabled',
             response: response,
             time: new Date().toLocaleTimeString()
           });
       });
       this.regula.on('checkstatus', response => {
         this.sdkRegula.events.unshift({
             event: 'Regula status',
             response: response,
             time: new Date().toLocaleTimeString()
           });
       });
       this.regula.on('opticalChange', response => {
         this.sdkRegula.events.unshift({
             event: 'Regula Optical Change',
             response: response,
             time: new Date().toLocaleTimeString()
           });
       });
     });
     
   },
   computed: {
  formatDataForDialog() {
    if (typeof this.dialogData === "string") {
      try {
        return JSON.stringify(JSON.parse(this.dialogData), null, 2);
      } catch (e) {
        return "Invalid JSON data"; 
      }
    } else {
      return JSON.stringify(this.dialogData, null, 2); // If it's already an object, stringify directly
    }
  }
},
   watch: {
     tab(val) {
       console.log(val)
     },
     responseData() { 
      this.fetchContent();
     },
   },
   methods: {
     highlighter(code) {
       return highlight(code, languages.html, 'html')
     },
     handleIncomingEvent(event) {
       // Process the incoming event and update the eventGroups array accordingly
       const requestUUID = event.request_uuid;
       
       // Check if an object for this request_uuid already exists in eventGroups
       const index = this.eventGroups.findIndex(group => group.request_uuid === requestUUID);
 
       if (index === -1) {
         // Create a new object for this request_uuid if it doesn't exist
         this.eventGroups.push({
           request_uuid: requestUUID,
           checkProgress: event.details.checkedPercentage,
           filesToDownload: event.details.filesToDownload,
           downloadProgress: event.details.downloaded,
           location: event.details.fileChanged
         });
       } else {
         // Update the existing object's properties
         if (event.details.checkedPercentage !== undefined) {
           this.eventGroups[index].checkProgress = event.details.checkedPercentage;
         }
         if (event.details.downloaded !== undefined) {
           this.eventGroups[index].downloadProgress = event.details.downloaded;
         }
       }
     },
     copyResponse(data) {
       this.dialog = true;
       this.dialogData = data;
       navigator.clipboard.writeText(data).then(() => {
         this.alertMsg = 'Copied to clipboard';
         setTimeout(() => {
           this.alertMsg = '';
         }, 2000);
       })
     },
     getPrintServiceInfo() {
       this.print.getServiceInfo().then(response => {
         this.sdkPrint.events.unshift({
           event: 'GetServiceInfo',
           response: JSON.stringify(response),
           time: new Date().toLocaleTimeString()
         });
       })
     },
     printModal() {
       this.modalPrint = true;
     },
     getPrinters() {
       this.print.getPrinters().then(response => {
         this.sdkPrint.events.unshift({
           event: 'GetPrinters',
           response: JSON.stringify(response),
           time: new Date().toLocaleTimeString()
         });
       })
     },
     getPrintersIndex() {
       this.print.index().then(response => {
         this.sdkPrint.events.unshift({
           event: 'GetPrintersIndex',
           response: JSON.stringify(response),
           time: new Date().toLocaleTimeString()
         });
       });
     },
     getPrinterStatus() {
       this.print.index().then(response => {
         let queueName = response.printers[0].printer.queueName;
         let configIndex = response.printers[0].printer.id;
         console.log("Queue name: " + queueName);
         console.log("Config index: " + configIndex);
         this.print.getStatus(queueName, configIndex).then(response => {
           this.sdkPrint.events.unshift({
             event: 'GetPrinterStatus',
             response: JSON.stringify(response),
             time: new Date().toLocaleTimeString()
           });
         });
       });
     },
     setPrinterStatusFault(faultName, status) {
       this.print.setPrinterStatus(faultName, status).then(response => {
         // Assuming `sdkPrint.events` is an array to store events, modify as per your actual data structure
         this.sdkPrint.events.unshift({
           event: 'Printer Status Fault',
           response: JSON.stringify(response),
           time: new Date().toLocaleTimeString()
         });
       }).then(()=>{
         this.faultName = "";
       }).catch(error => {
         console.error('Error setting printer status:', error);
         // Handle the error appropriately
       });
     },
     changeAcceptorStatus(acceptor, status) {
       this.money.changeAcceptorStatus(acceptor, status).then(response => {
         this.sdkMoney.events.unshift({
         event: "ChangeAcceptorStatus",
         response: JSON.stringify(response),
         time: new Date().toLocaleTimeString()
       })
       })
     },
     getStatus(acceptor) {
       this.money.getAcceptorStatus(acceptor).then(response => {
         console.log("Get status response" + response);
         this.sdkMoney.events.unshift({
         event: "GetAcceptorsStatus",
         response: JSON.stringify(response),
         time: new Date().toLocaleTimeString()
       });
       });
     },
     acceptMoney(bool) {
       this.money.changeAcceptorStatusAll(bool).then(response =>{
         console.log(response)
         this.sdkMoney.events.unshift({
         event: "ChangeAcceptorsStatus(" + bool + ")",
         response: JSON.stringify(response),
         time: new Date().toLocaleTimeString()
       });
       })
     },
     getPeripherals() {
       this.money.getPeripheralsInfo().then(response => {
         this.sdkMoney.events.unshift({
           event: "GetPeripheralsInfo",
           response: JSON.stringify(response),
           time: new Date().toLocaleTimeString()
         });
       });
     },
     runPrint() {
       this.print.index().then(response => {
         let currentPrinterModel = response.printers[0].printer.model
         let printerIndex = response.printers[0].printer.id;
         this.print.addPrintJob(this.printTemplate, currentPrinterModel, printerIndex).then(response => {
           this.sdkPrint.events.unshift({
             event: "AddPrintJob",
             response: JSON.stringify(response),
             time: new Date().toLocaleTimeString()
           });
         });
         this.modalPrint = false;
       });
     },
     //fetch content from url 
     getContent() {
       fetch(`https://assets-api.nsoft.io/load_manifest/${this.dataValue}`)
       .then(response => response.json())
       .then(data => {
         this.responseData = data.data;
         this.sdkFetcher.events.unshift({
           event: "GetManifest",
           response: JSON.stringify(data),
           time: new Date().toLocaleTimeString()
         });
       }).catch(error => {
         console.log(error);
       });    
     },
     //download from fetcher
     fetchContent(){
       this.fetcher.fetchContent(this.responseData.uuid, this.responseData.manifest).then(response => {
         this.sdkFetcher.events.unshift({
           event: "GetContent",
           response: response,
           time: new Date().toLocaleTimeString()
         })
       });  
     },
     stopDownload(data){
       this.fetcher.stopDownload(data).then(response =>{
         console.log(response)
       })
     },
     getContentDisplay(){
       let data = this.appRunUuid; 
       this.display.getApplication(data).then(response => {
         this.sdkDisplay.events.unshift({
         event: "getDisplayConfig",
         response: response,
         time: new Date().toLocaleTimeString()
       })
       })
     },
     getListRunningApps(){
      this.display.getListRunningApplications().then(response =>{
         this.sdkDisplay.events.unshift({
         event: "getListOfRunningApps",
         response: response,
         time: new Date().toLocaleTimeString()
       }) 
      })
     },
     applicationToFront(){
      let data = this.appId;
      this.display.BringAppToFront(data).then(response =>{
         this.sdkDisplay.events.unshift({
         event: "applicationToFront",
         response: response,
         time: new Date().toLocaleTimeString()
       }) 
      })
     },
     cardWrite(){
       let data = this.cardText
       this.card.writeCard(data);
       this.cardWriteModal = true;
     },
     cardReadMock(){
       this.card.mockCardRead().then(response => {
          console.log("citanje uspjesno" + response)
       })
     },
     startCard() {
      this.card.cardReaderStart().then(response => {
         console.log('Card reader started:', response);
         this.sdkCard.events.unshift({
         event: 'Card Reader Started',
         response: JSON.stringify(response),
         time: new Date().toLocaleTimeString()
         });
      }).catch(error => {
         console.error('Error starting card reader:', error);
         this.alertMsg = 'Failed to start card reader: ' + error.message;
      });
   },

  stopCard() {
    this.card.cardReaderStop().then(response => {
      console.log('Card reader stopped:', response);
      this.sdkCard.events.unshift({
        event: 'Card Reader Stopped',
        response: JSON.stringify(response),
        time: new Date().toLocaleTimeString()
      });
    }).catch(error => {
      console.error('Error stopping card reader:', error);
      this.alertMsg = 'Failed to stop card reader: ' + error.message;
    });
  },
     getDeviceState(){
       this.sys.deviceState().then(response => {
         this.sdkRelay.events.unshift({
         event: "deviceState",
         response: JSON.stringify(response),
         time: new Date().toLocaleTimeString()
       })
       })
     },
     getDisplayApplications(){
       this.sys.getDisplayApplication().then(response =>{
         console.log(response)
         this.sdkRelay.events.unshift({
         event: "getDeviceApplication",
         response: JSON.stringify(response),
         time: new Date().toLocaleTimeString()
       })
       })
     },
     fetchTransactions(){
       this.pocket.fetchTransactions().then(response =>{
           this.sdkPocket.events.unshift({
           event: "getTransactions",
           response: JSON.stringify(response),
           time: new Date().toLocaleTimeString()
         })
       })
     },
     fetchTransactionApoens(){
       this.pocket.fetchTransApoens().then(response =>{
           this.sdkPocket.events.unshift({
           event: "getTransactions",
           response: JSON.stringify(response),
           time: new Date().toLocaleTimeString()
         })
       })
     },
     cancelTransactions(){
       this.pocket.payinCancel(this.id).then(response =>{
           this.sdkPocket.events.unshift({
           event: "getTransactions",
           response: JSON.stringify(response),
           time: new Date().toLocaleTimeString()
         })
       })
     },
     emptyCashBox(){
       this.pocket.emptyCashBox().then(response =>{
         console.log(response)
           this.sdkPocket.events.unshift({
           event: "getTransactions",
           response: JSON.stringify(response),
           time: new Date().toLocaleTimeString()
         })
       })
     },
     sendTransactionMock(){
      let device = this.deviceMock;
      let amount = this.amountMock;
      this.money.virtualMoneyInsert(device, amount).then(response => {
         console.log(response)
         this.deviceMock = "";
         this.amountMock = "";
         this.sdkPocket.events.unshift({
            event: "Balance updated",
            response: response,
            time: new Date().toLocaleDateString()
         })
      })
     },
     appHeartbeat(){
      let data = "fa69bf55-be0f-4c08-8666-3310e12f8423";
      this.display.sendAppHeartbeatInterval(data).then(response => {
         this.sdkDisplay.events.unshift({
         event: "Application Heartbeat response",
         response: response,
         time: new Date().toLocaleTimeString()
       })
      })
     },
     startRegulaService(){
      this.regula.enableDevice();
     },
     stopRegulaService(){
      this.regula.disableDevice();
     },
     checkRegulaService(){
      this.regula.checkDeviceStatus();
     }

 },
   data() {
     return {
       appName: null,
       appId: null,
       stopped: false,
       faultName: "",
       deviceMock: "",
       amountMock: "",
       eventGroups: [],
       cardWriteModal: false,
       dialog: false,
       responseData: {},
       dialogData: null,
       dataMessage: false,
       drawer: true,
       mockDevice: "",
       mockAmount: "",
       alertMsg: "",
       deviceState: {},
       lpsConnection: false,
       sys: {},
       modalPrint: false,
       sdkMoney: {
         connection: false,
         events: [],
       },
       sdkCard: {
         connection: false,
         events: [],
       },
       sdkPrint: {
         connection: false,
         events: [],
       },
       sdkFetcher: {
         connection: false,
         events: [],
       },
       sdkFetcherData: {
         connection: false,
         events: [],
       },
       sdkDisplay: {
         connection: false,
         events: [],
       },
       sdkRelay: {
         connection: false,
         events: [],
       },
       sdkPocket: {
         connection: false,
         events: [],
       },
       sdkRegula:{
         connection: false,
         events: []
       },
       tab: 0,
       cardText: "",
       dataValue: "",
       appRunUuid: "",
       items: [
         {title: 'Money', icon: 'mdi-cash'},
         {title: 'Card Reader', icon: 'mdi-card'},
         {title: 'Print', icon: 'mdi-printer'},
         {title: 'Fetcher', icon: 'mdi-download'},
         {title: 'Relay', icon: 'mdi-message'},
         {title: 'Display', icon: 'mdi-monitor'},
         {title: 'Pocket', icon: 'mdi-cash-lock-open'},
         {title: 'Regula', icon: 'mdi-barcode-scan'},
       ],
       itemsPrint: ["Paper near end sensor", "Thermal head cover", "Paper end", "Thermal head temp", "Auto cutter", "Paper detection", "Paper clamp status"],
       mini: true,
       printTemplate: `<html data-margin="0" data-line-spacing="5" data-width-reduction="0">
    <head>
       title=LiveBetting
    </head>
    <body>
       <div class="row center">
          <logo/>
       </div>
       <div class="row">
          <p class="left">
             <span>Betshop: marino, kneza domagoja</span>
          </p>
          <p class="left">
             <span>Device: </span>
          </p>
          <p class="left">
             <span>Issuer: vedo</span>
          </p>
       </div>
       <div class="row">
          <br/>
       </div>
       <div class="row">
          <h1 class="center bold">Live Betting</h1>
       </div>
       <div class="row"><br/></div>
       <div class="row">
          <hr class="equals"/>
       </div>
       <div class="row">
          <div class="col">
             <p class="left">
                <span class="bold">Issued</span>
             </p>
          </div>
          <div class="col">
             <p class="right">
                <span class="bold">10.10. 13:36:32</span>
             </p>
          </div>
       </div>
       <div class="row">
          <div class="col">
             <p class="left">
                <span class="bold">Ticket ID</span>
             </p>
          </div>
          <div class="col">
             <p class="right">
                <span class="bold">X117V29FD</span>
             </p>
          </div>
       </div>
       <div class="row">
          <hr class="equals"/>
       </div>
       <div class="row"><br/></div>
       <div class="row">
          <hr class="dash"/>
       </div>
       <div class="row">
          <p class="center">
             <span class="bold">Combo</span>
          </p>
       </div>
       <div class="row">
          <hr class="dash"/>
       </div>
       <div class="row">
          <div class="col" data-width="1">
             <p class="left">
                <span></span>
             </p>
          </div>
          <div class="col" data-width="6">
             <p class="right">
                <span class="bold">610 </span>
             </p>
          </div>
          <div class="col">
             <p class="left">
                <span class="bold">Trinker, Jonas - Parizzia, Nicolas</span>
             </p>
          </div>
       </div>
       <div class="row">
          <div class="col" data-width="7">
             <p class="left">
                <span></span>
             </p>
          </div>
          <div class="col">
             <p class="left">
                <span>Who will win first [Z] points of
                game [Y] of set [X]</span>
                <span> (3|5|2)</span>
             </p>
          </div>
       </div>
       <div class="row">
          <div class="col" data-width="7">
             <p class="left">
                <span></span>
             </p>
          </div>
          <div class="col" data-width="22">
             <p class="left">
                <span>Pick: </span><span>x</span>
             </p>
          </div>
          <div class="col">
             <p class="right">
                <span>1.92</span>
             </p>
          </div>
       </div>
       <div class="row">
          <hr class="dash"/>
       </div>
       <div class="row">
          <div class="col" data-width="1">
             <p class="left">
                <span></span>
             </p>
          </div>
          <div class="col" data-width="6">
             <p class="right">
                <span class="bold">816 </span>
             </p>
          </div>
          <div class="col">
             <p class="left">
                <span class="bold">Saccani, Silvia - Lanca, Sara</span>
             </p>
          </div>
       </div>
       <div class="row">
          <div class="col" data-width="7">
             <p class="left">
                <span></span>
             </p>
          </div>
          <div class="col">
             <p>
                <span>Which player will win the match?</span>
             </p>
          </div>
       </div>
       <div class="row">
          <div class="col" data-width="7">
             <p class="left">
                <span></span>
             </p>
          </div>
          <div class="col" data-width="29">
             <p class="left">
                <span>Pick: </span>
                <span>Saccani, Silvia </span>
             </p>
          </div>
          <div class="col">
             <p class="right">
                <span>1.33</span>
             </p>
          </div>
       </div>
       <div class="row">
          <hr class="dash"/>
       </div>
       <div class="row"><br/></div>
       <div class="row">
          <div class="col">
             <p class="left">
                <span class="bold">Total odds:</span>
             </p>
          </div>
          <div class="col">
             <p class="right">
                <span class="bold">2.55</span>
             </p>
          </div>
       </div>
       <div class="row"><br/></div>
       <div class="row">
          <hr class="equals"/>
       </div>
       <div class="row">
          <div class="col">
             <p class="left">
                <span class="bold">Stake:</span>
             </p>
          </div>
          <div class="col">
             <p class="right">
                <span class="bold">1,00 BAM</span>
             </p>
          </div>
       </div>
       <div class="row">
          <hr class="dash"/>
       </div>
       <div class="row">
          <div class="col">
             <p class="left">
                <span class="bold">Max winnings:</span>
             </p>
          </div>
          <div class="col">
             <p class="right">
                <span class="bold">2,55 BAM</span>
             </p>
          </div>
       </div>
       <div class="row">
          <hr class="equals"/>
       </div>
       <div class="row">
          <br/>
       </div>
       <div class="row center">
          <barcode value="X117V29FD"></barcode>
       </div>
 </html>
 <html data-margin="0" data-line-spacing="5" data-width-reduction="0">
    <head>
       title=LiveBetting
    </head>
    <body>
       <div class="row center">
          <logo/>
       </div>
       <div class="row">
          <p class="left">
             <span>Betshop: marino, kneza domagoja</span>
          </p>
          <p class="left">
             <span>Device: </span>
          </p>
          <p class="left">
             <span>Issuer: vedo</span>
          </p>
       </div>
       <div class="row">
          <br/>
       </div>
       <div class="row">
          <h1 class="center bold">Live Betting</h1>
       </div>
       <div class="row"><br/></div>
       <div class="row">
          <hr class="equals"/>
       </div>
       <div class="row">
          <div class="col">
             <p class="left">
                <span class="bold">Issued</span>
             </p>
          </div>
          <div class="col">
             <p class="right">
                <span class="bold">10.10. 13:36:32</span>
             </p>
          </div>
       </div>
       <div class="row">
          <div class="col">
             <p class="left">
                <span class="bold">Ticket ID</span>
             </p>
          </div>
          <div class="col">
             <p class="right">
                <span class="bold">X117V29FD</span>
             </p>
          </div>
       </div>
       <div class="row">
          <hr class="equals"/>
       </div>
       <div class="row"><br/></div>
       <div class="row">
          <hr class="dash"/>
       </div>
       <div class="row">
          <p class="center">
             <span class="bold">Combo</span>
          </p>
       </div>
       <div class="row">
          <hr class="dash"/>
       </div>
       <div class="row">
          <div class="col" data-width="1">
             <p class="left">
                <span></span>
             </p>
          </div>
          <div class="col" data-width="6">
             <p class="right">
                <span class="bold">610 </span>
             </p>
          </div>
          <div class="col">
             <p class="left">
                <span class="bold">Trinker, Jonas - Parizzia, Nicolas</span>
             </p>
          </div>
       </div>
       <div class="row">
          <div class="col" data-width="7">
             <p class="left">
                <span></span>
             </p>
          </div>
          <div class="col">
             <p class="left">
                <span>Who will win first [Z] points of
                game [Y] of set [X]</span>
                <span> (3|5|2)</span>
             </p>
          </div>
       </div>
       <div class="row">
          <div class="col" data-width="7">
             <p class="left">
                <span></span>
             </p>
          </div>
          <div class="col" data-width="22">
             <p class="left">
                <span>Pick: </span><span>x</span>
             </p>
          </div>
          <div class="col">
             <p class="right">
                <span>1.92</span>
             </p>
          </div>
       </div>
       <div class="row">
          <hr class="dash"/>
       </div>
       <div class="row">
          <div class="col" data-width="1">
             <p class="left">
                <span></span>
             </p>
          </div>
          <div class="col" data-width="6">
             <p class="right">
                <span class="bold">816 </span>
             </p>
          </div>
          <div class="col">
             <p class="left">
                <span class="bold">Saccani, Silvia - Lanca, Sara</span>
             </p>
          </div>
       </div>
       <div class="row">
          <div class="col" data-width="7">
             <p class="left">
                <span></span>
             </p>
          </div>
          <div class="col">
             <p>
                <span>Which player will win the match?</span>
             </p>
          </div>
       </div>
       <div class="row">
          <div class="col" data-width="7">
             <p class="left">
                <span></span>
             </p>
          </div>
          <div class="col" data-width="29">
             <p class="left">
                <span>Pick: </span>
                <span>Saccani, Silvia </span>
             </p>
          </div>
          <div class="col">
             <p class="right">
                <span>1.33</span>
             </p>
          </div>
       </div>
       <div class="row">
          <hr class="dash"/>
       </div>
       <div class="row"><br/></div>
       <div class="row">
          <div class="col">
             <p class="left">
                <span class="bold">Total odds:</span>
             </p>
          </div>
          <div class="col">
             <p class="right">
                <span class="bold">2.55</span>
             </p>
          </div>
       </div>
       <div class="row"><br/></div>
       <div class="row">
          <hr class="equals"/>
       </div>
       <div class="row">
          <div class="col">
             <p class="left">
                <span class="bold">Stake:</span>
             </p>
          </div>
          <div class="col">
             <p class="right">
                <span class="bold">1,00 BAM</span>
             </p>
          </div>
       </div>
       <div class="row">
          <hr class="dash"/>
       </div>
       <div class="row">
          <div class="col">
             <p class="left">
                <span class="bold">Max winnings:</span>
             </p>
          </div>
          <div class="col">
             <p class="right">
                <span class="bold">2,55 BAM</span>
             </p>
          </div>
       </div>
       <div class="row">
          <hr class="equals"/>
       </div>
       <div class="row">
          <br/>
       </div>
       <div class="row center">
          <barcode value="X117V29FD"></barcode>
       </div>
 </html>
 <html data-margin="0" data-line-spacing="5" data-width-reduction="0">
    <head>
       title=LiveBetting
    </head>
    <body>
       <div class="row center">
          <logo/>
       </div>
       <div class="row">
          <p class="left">
             <span>Betshop: marino, kneza domagoja</span>
          </p>
          <p class="left">
             <span>Device: </span>
          </p>
          <p class="left">
             <span>Issuer: vedo</span>
          </p>
       </div>
       <div class="row">
          <br/>
       </div>
       <div class="row">
          <h1 class="center bold">Live Betting</h1>
       </div>
       <div class="row"><br/></div>
       <div class="row">
          <hr class="equals"/>
       </div>
       <div class="row">
          <div class="col">
             <p class="left">
                <span class="bold">Issued</span>
             </p>
          </div>
          <div class="col">
             <p class="right">
                <span class="bold">10.10. 13:36:32</span>
             </p>
          </div>
       </div>
       <div class="row">
          <div class="col">
             <p class="left">
                <span class="bold">Ticket ID</span>
             </p>
          </div>
          <div class="col">
             <p class="right">
                <span class="bold">X117V29FD</span>
             </p>
          </div>
       </div>
       <div class="row">
          <hr class="equals"/>
       </div>
       <div class="row"><br/></div>
       <div class="row">
          <hr class="dash"/>
       </div>
       <div class="row">
          <p class="center">
             <span class="bold">Combo</span>
          </p>
       </div>
       <div class="row">
          <hr class="dash"/>
       </div>
       <div class="row">
          <div class="col" data-width="1">
             <p class="left">
                <span></span>
             </p>
          </div>
          <div class="col" data-width="6">
             <p class="right">
                <span class="bold">610 </span>
             </p>
          </div>
          <div class="col">
             <p class="left">
                <span class="bold">Trinker, Jonas - Parizzia, Nicolas</span>
             </p>
          </div>
       </div>
       <div class="row">
          <div class="col" data-width="7">
             <p class="left">
                <span></span>
             </p>
          </div>
          <div class="col">
             <p class="left">
                <span>Who will win first [Z] points of
                game [Y] of set [X]</span>
                <span> (3|5|2)</span>
             </p>
          </div>
       </div>
       <div class="row">
          <div class="col" data-width="7">
             <p class="left">
                <span></span>
             </p>
          </div>
          <div class="col" data-width="22">
             <p class="left">
                <span>Pick: </span><span>x</span>
             </p>
          </div>
          <div class="col">
             <p class="right">
                <span>1.92</span>
             </p>
          </div>
       </div>
       <div class="row">
          <hr class="dash"/>
       </div>
       <div class="row">
          <div class="col" data-width="1">
             <p class="left">
                <span></span>
             </p>
          </div>
          <div class="col" data-width="6">
             <p class="right">
                <span class="bold">816 </span>
             </p>
          </div>
          <div class="col">
             <p class="left">
                <span class="bold">Saccani, Silvia - Lanca, Sara</span>
             </p>
          </div>
       </div>
       <div class="row">
          <div class="col" data-width="7">
             <p class="left">
                <span></span>
             </p>
          </div>
          <div class="col">
             <p>
                <span>Which player will win the match?</span>
             </p>
          </div>
       </div>
       <div class="row">
          <div class="col" data-width="7">
             <p class="left">
                <span></span>
             </p>
          </div>
          <div class="col" data-width="29">
             <p class="left">
                <span>Pick: </span>
                <span>Saccani, Silvia </span>
             </p>
          </div>
          <div class="col">
             <p class="right">
                <span>1.33</span>
             </p>
          </div>
       </div>
       <div class="row">
          <hr class="dash"/>
       </div>
       <div class="row"><br/></div>
       <div class="row">
          <div class="col">
             <p class="left">
                <span class="bold">Total odds:</span>
             </p>
          </div>
          <div class="col">
             <p class="right">
                <span class="bold">2.55</span>
             </p>
          </div>
       </div>
       <div class="row"><br/></div>
       <div class="row">
          <hr class="equals"/>
       </div>
       <div class="row">
          <div class="col">
             <p class="left">
                <span class="bold">Stake:</span>
             </p>
          </div>
          <div class="col">
             <p class="right">
                <span class="bold">1,00 BAM</span>
             </p>
          </div>
       </div>
       <div class="row">
          <hr class="dash"/>
       </div>
       <div class="row">
          <div class="col">
             <p class="left">
                <span class="bold">Max winnings:</span>
             </p>
          </div>
          <div class="col">
             <p class="left">
                <span class="bold">Trinker, Jonas - Parizzia, Nicolas</span>
             </p>
          </div>
       </div>
       <div class="row">
          <div class="col" data-width="7">
             <p class="left">
                <span></span>
             </p>
          </div>
          <div class="col">
             <p class="left">
                <span>Who will win first [Z] points of
                game [Y] of set [X]</span>
                <span> (3|5|2)</span>
             </p>
          </div>
       </div>
       <div class="row">
          <div class="col" data-width="7">
             <p class="left">
                <span></span>
             </p>
          </div>
          <div class="col" data-width="22">
             <p class="left">
                <span>Pick: </span><span>x</span>
             </p>
          </div>
          <div class="col">
             <p class="right">
                <span>1.92</span>
             </p>
          </div>
       </div>
       <div class="row">
          <hr class="dash"/>
       </div>
       <div class="row">
          <div class="col" data-width="1">
             <p class="left">
                <span></span>
             </p>
          </div>
          <div class="col" data-width="6">
             <p class="right">
                <span class="bold">816 </span>
             </p>
          </div>
          <div class="col">
             <p class="left">
                <span class="bold">Saccani, Silvia - Lanca, Sara</span>
             </p>
          </div>
       </div>
       <div class="row">
          <div class="col" data-width="7">
             <p class="left">
                <span></span>
             </p>
          </div>
          <div class="col">
             <p>
                <span>Which player will win the match?</span>
             </p>
          </div>
       </div>
       <div class="row">
          <div class="col" data-width="7">
             <p class="left">
                <span></span>
             </p>
          </div>
          <div class="col" data-width="29">
             <p class="left">
                <span>Pick: </span>
                <span>Saccani, Silvia </span>
             </p>
          </div>
          <div class="col">
             <p class="right">
                <span>1.33</span>
             </p>
          </div>
       </div>
       <div class="row">
          <hr class="dash"/>
       </div>
       <div class="row"><br/></div>
       <div class="row">
          <div class="col">
             <p class="left">
                <span class="bold">Total odds:</span>
             </p>
          </div>
          <div class="col">
             <p class="right">
                <span class="bold">2.55</span>
             </p>
          </div>
       </div>
       <div class="row"><br/></div>
       <div class="row">
          <hr class="equals"/>
       </div>
       <div class="row">
          <div class="col">
             <p class="left">
                <span class="bold">Stake:</span>
             </p>
          </div>
          <div class="col">
             <p class="right">
                <span class="bold">1,00 BAM</span>
             </p>
          </div>
       </div>
       <div class="row">
          <hr class="dash"/>
       </div>
       <div class="row">
          <div class="col">
             <p class="left">
                <span class="bold">Max winnings:</span>
             </p>
          </div>
          <div class="col">
             <p class="right">
                <span class="bold">2,55 BAM</span>
             </p>
          </div>
       </div>
       <div class="row">
          <hr class="equals"/>
       </div>
       <div class="row">
          <br/>
       </div>
       <div class="row center">
          <barcode value="X117V29FD"></barcode>
       </div>
 </html>
 <html data-margin="0" data-line-spacing="5" data-width-reduction="0">
    <head>
       title=LiveBetting
    </head>
    <body>
       <div class="row center">
          <logo/>
       </div>
       <div class="row">
          <p class="left">
             <span>Betshop: marino, kneza domagoja</span>
          </p>
          <p class="left">
             <span>Device: </span>
          </p>
          <p class="left">
             <span>Issuer: vedo</span>
          </p>
       </div>
       <div class="row">
          <br/>
       </div>
       <div class="row">
          <h1 class="center bold">Live Betting</h1>
       </div>
       <div class="row"><br/></div>
       <div class="row">
          <hr class="equals"/>
       </div>
       <div class="row">
          <div class="col">
             <p class="left">
                <span class="bold">Issued</span>
             </p>
          </div>
          <div class="col">
             <p class="right">
                <span class="bold">10.10. 13:36:32</span>
             </p>
          </div>
       </div>
       <div class="row">
          <div class="col">
             <p class="left">
                <span class="bold">Ticket ID</span>
             </p>
          </div>
          <div class="col">
             <p class="right">
                <span class="bold">X117V29FD</span>
             </p>
          </div>
       </div>
       <div class="row">
          <hr class="equals"/>
       </div>
       <div class="row"><br/></div>
       <div class="row">
          <hr class="dash"/>
       </div>
       <div class="row">
          <p class="center">
             <span class="bold">Combo</span>
          </p>
       </div>
       <div class="row">
          <hr class="dash"/>
       </div>
       <div class="row">
          <div class="col" data-width="1">
             <p class="left">
                <span></span>
             </p>
          </div>
          <div class="col" data-width="6">
             <p class="right">
                <span class="bold">610 </span>
             </p>
          </div>
          <div class="col">
             <p class="left">
                <span class="bold">Trinker, Jonas - Parizzia, Nicolas</span>
             </p>
          </div>
       </div>
       <div class="row">
          <div class="col" data-width="7">
             <p class="left">
                <span></span>
             </p>
          </div>
          <div class="col">
             <p class="left">
                <span>Who will win first [Z] points of
                game [Y] of set [X]</span>
                <span> (3|5|2)</span>
             </p>
          </div>
       </div>
       <div class="row">
          <div class="col" data-width="7">
             <p class="left">
                <span></span>
             </p>
          </div>
          <div class="col" data-width="22">
             <p class="left">
                <span>Pick: </span><span>x</span>
             </p>
          </div>
          <div class="col">
             <p class="right">
                <span>1.92</span>
             </p>
          </div>
       </div>
       <div class="row">
          <hr class="dash"/>
       </div>
       <div class="row">
          <div class="col" data-width="1">
             <p class="left">
                <span></span>
             </p>
          </div>
          <div class="col" data-width="6">
             <p class="right">
                <span class="bold">816 </span>
             </p>
          </div>
          <div class="col">
             <p class="left">
                <span class="bold">Saccani, Silvia - Lanca, Sara</span>
             </p>
          </div>
       </div>
       <div class="row">
          <div class="col" data-width="7">
             <p class="left">
                <span></span>
             </p>
          </div>
          <div class="col">
             <p>
                <span>Which player will win the match?</span>
             </p>
          </div>
       </div>
       <div class="row">
          <div class="col" data-width="7">
             <p class="left">
                <span></span>
             </p>
          </div>
          <div class="col" data-width="29">
             <p class="left">
                <span>Pick: </span>
                <span>Saccani, Silvia </span>
             </p>
          </div>
          <div class="col">
             <p class="right">
                <span>1.33</span>
             </p>
          </div>
       </div>
       <div class="row">
          <hr class="dash"/>
       </div>
       <div class="row"><br/></div>
       <div class="row">
          <div class="col">
             <p class="left">
                <span class="bold">Total odds:</span>
             </p>
          </div>
          <div class="col">
             <p class="right">
                <span class="bold">2.55</span>
             </p>
          </div>
       </div>
       <div class="row"><br/></div>
       <div class="row">
          <hr class="equals"/>
       </div>
       <div class="row">
          <div class="col">
             <p class="left">
                <span class="bold">Stake:</span>
             </p>
          </div>
          <div class="col">
             <p class="right">
                <span class="bold">1,00 BAM</span>
             </p>
          </div>
       </div>
       <div class="row">
          <hr class="dash"/>
       </div>
       <div class="row">
          <div class="col">
             <p class="left">
                <span class="bold">Max winnings:</span>
             </p>
          </div>
          <div class="col">
             <p class="right">
                <span class="bold">2,55 BAM</span>
             </p>
          </div>
       </div>
       <div class="row">
          <hr class="equals"/>
       </div>
       <div class="row">
          <br/>
       </div>
       <div class="row center">
          <barcode value="X117V29FD"></barcode>
       </div>
 </html>
 <html data-margin="0" data-line-spacing="5" data-width-reduction="0">
    <head>
       title=LiveBetting
    </head>
    <body>
       <div class="row center">
          <logo/>
       </div>
       <div class="row">
          <p class="left">
             <span>Betshop: marino, kneza domagoja</span>
          </p>
          <p class="left">
             <span>Device: </span>
          </p>
          <p class="left">
             <span>Issuer: vedo</span>
          </p>
       </div>
       <div class="row">
          <br/>
       </div>
       <div class="row">
          <h1 class="center bold">Live Betting</h1>
       </div>
       <div class="row"><br/></div>
       <div class="row">
          <hr class="equals"/>
       </div>
       <div class="row">
          <div class="col">
             <p class="left">
                <span class="bold">Issued</span>
             </p>
          </div>
          <div class="col">
             <p class="right">
                <span class="bold">10.10. 13:36:32</span>
             </p>
          </div>
       </div>
       <div class="row">
          <div class="col">
             <p class="left">
                <span class="bold">Ticket ID</span>
             </p>
          </div>
          <div class="col">
             <p class="right">
                <span class="bold">X117V29FD</span>
             </p>
          </div>
       </div>
       <div class="row">
          <hr class="equals"/>
       </div>
       <div class="row"><br/></div>
       <div class="row">
          <hr class="dash"/>
       </div>
       <div class="row">
          <p class="center">
             <span class="bold">Combo</span>
          </p>
       </div>
       <div class="row">
          <hr class="dash"/>
       </div>
       <div class="row">
          <div class="col" data-width="1">
             <p class="left">
                <span></span>
             </p>
          </div>
          <div class="col" data-width="6">
             <p class="right">
                <span class="bold">610 </span>
             </p>
          </div>
          <div class="col">
             <p class="left">
                <span class="bold">Trinker, Jonas - Parizzia, Nicolas</span>
             </p>
          </div>
       </div>
       <div class="row">
          <div class="col" data-width="7">
             <p class="left">
                <span></span>
             </p>
          </div>
          <div class="col">
             <p class="left">
                <span>Who will win first [Z] points of
                game [Y] of set [X]</span>
                <span> (3|5|2)</span>
             </p>
          </div>
       </div>
       <div class="row">
          <div class="col" data-width="7">
             <p class="left">
                <span></span>
             </p>
          </div>
          <div class="col" data-width="22">
             <p class="left">
                <span>Pick: </span><span>x</span>
             </p>
          </div>
          <div class="col">
             <p class="right">
                <span>1.92</span>
             </p>
          </div>
       </div>
       <div class="row">
          <hr class="dash"/>
       </div>
       <div class="row">
          <div class="col" data-width="1">
             <p class="left">
                <span></span>
             </p>
          </div>
          <div class="col" data-width="6">
             <p class="right">
                <span class="bold">816 </span>
             </p>
          </div>
          <div class="col">
             <p class="left">
                <span class="bold">Saccani, Silvia - Lanca, Sara</span>
             </p>
          </div>
       </div>
       <div class="row">
          <div class="col" data-width="7">
             <p class="left">
                <span></span>
             </p>
          </div>
          <div class="col">
             <p>
                <span>Which player will win the match?</span>
             </p>
          </div>
       </div>
       <div class="row">
          <div class="col" data-width="7">
             <p class="left">
                <span></span>
             </p>
          </div>
          <div class="col" data-width="29">
             <p class="left">
                <span>Pick: </span>
                <span>Saccani, Silvia </span>
             </p>
          </div>
          <div class="col">
             <p class="right">
                <span>1.33</span>
             </p>
          </div>
       </div>
       <div class="row">
          <hr class="dash"/>
       </div>
       <div class="row"><br/></div>
       <div class="row">
          <div class="col">
             <p class="left">
                <span class="bold">Total odds:</span>
             </p>
          </div>
          <div class="col">
             <p class="right">
                <span class="bold">2.55</span>
             </p>
          </div>
       </div>
       <div class="row"><br/></div>
       <div class="row">
          <hr class="equals"/>
       </div>
       <div class="row">
          <div class="col">
             <p class="left">
                <span class="bold">Stake:</span>
             </p>
          </div>
          <div class="col">
             <p class="right">
                <span class="bold">1,00 BAM</span>
             </p>
          </div>
       </div>
       <div class="row">
          <hr class="dash"/>
       </div>
       <div class="row">
          <div class="col">
             <p class="left">
                <span class="bold">Max winnings:</span>
             </p>
          </div>
          <div class="col">
             <p class="right">
                <span class="bold">2,55 BAM</span>
             </p>
          </div>
       </div>
       <div class="row">
          <hr class="equals"/>
       </div>
       <div class="row">
          <br/>
       </div>
       <div class="row center">
          <barcode value="X117V29FD"></barcode>
       </div>
 </html>
 `
     }
     
   }
 };
 </script>
 
 <style scoped lang="scss">
 .circle {
   width: 8px;
   height: 8px;
   border-radius: 51%;
 }
 
 .content-wrapper {
   height: calc(100vh - 8px);
 }
 
 .content-border {
   border: 1px solid #e0e0e0;
   border-radius: 4px;
 }
 
 .green {
   background-color: var(--v-green);
 }
 
 .red {
   background-color: var(--v-red);
 }
 
 .letter-spacing-normal {
   letter-spacing: normal;
 }
 
 .btn {
   background-color: transparent !important;
 }
 
 .event:nth-child(even) {
   background-color: #f1fff1;
 }
 
 
 /* required class */
 .my-editor {
   /* we dont use `language-` classes anymore so thats why we need to add background and text color manually */
   background: #2d2d2d;
   color: #ccc;
 
   /* you must provide font-family font-size line-height. Example: */
   font-family: Fira code, Fira Mono, Consolas, Menlo, Courier, monospace;
   font-size: 14px;
   line-height: 1.5;
   padding: 5px;
 }
 
 /* optional class for removing the outline */
 .prism-editor__textarea:focus {
   outline: none;
 }
 
 .v-dialog--scrollable {
     position: absolute;
     right: 0;
     margin: 0;
     border-radius: 0;
   }
 
   .v-dialog:not(.v-dialog--fullscreen) {
     min-height: 100%;
     box-shadow: none;
   }
   .div_border{
     border: 1p solid gray;
   }
 
 </style>
 